(function($) {
	/*
	 * Toggles for address info
	 */
	var Toggles = {

		toggleCheck: function(element, invert) {
			if (invert) {
				return !element.is(':checked');
			}

			return element.is(':checked');
		},

		// function to copy values from source parent element to destination element
		copyValuesFromTo: function(source, dest) {
			var matcher = /data\[.+\]\[(.+)\]/;
			var valuesMap = {};
			$(':input', source).each(function() {
				var el = $(this);
				var key = $(el.attr('name').match(matcher)).get(1);
				valuesMap[key] = el.val();
			});

			$(':input', dest).each(function() {
				var el = $(this);
				var key = $(el.attr('name').match(matcher)).get(1);

				if (valuesMap[key] && !el.val()) {
					el.val(valuesMap[key]);
				}
			});
		},

		init: function() {

			var self = this;

			$('[data-toggle]').each(function() {
				var element = $(this);

				if (element.data('copyValues')) {
					var copyValuesSourceEl = $('#'+ element.data('copyValues'));
				}

				var toggleSelector = element.attr('data-toggle');
				var toggleElement = $('#'+ toggleSelector);

				// if invert is set, the check will act in a reverse order
				var toggleInvert = element.attr('data-toggle-invert') || false;

				if(self.toggleCheck(element, toggleInvert)) {
					toggleElement.hide();
				}

				element.on('click', function() {
					if (self.toggleCheck($(this), toggleInvert)) {
						toggleElement.hide();
					} else {
						toggleElement.show();
						if (copyValuesSourceEl) {
							self.copyValuesFromTo(copyValuesSourceEl, toggleElement);
						}
					}
				});
			});
		}
	};

	$(document).ready(function() {
		Toggles.init();
	});

})(jQuery);
