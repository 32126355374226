import '@fancyapps/fancybox';

export default class Facets {

    constructor() {
        let _parent = this;
        this.facets = $('.facet-container .facet');

        this.facets.each(function(i) {
            $(this).on({
                click: () => {
                    _parent.openModal(this);
                }
            });
        });
    }

    openModal(facet) {
        let newFacet = $(facet).clone();

        newFacet.find('input[type=checkbox]').on('click', function(cbe) {
            let link = $(this).next('a').attr('href');
            document.location.href = link;
        });

        $.fancybox.open(newFacet, {
            lang: 'nl',
            animationDuration: 100
        });
    }

}
