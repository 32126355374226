import Facets from './facets';
import 'jquery-zoom';
import { tns } from 'tiny-slider/src/tiny-slider';

import '../../Dirmacom/DirmacomShop/webroot/js/addresstoggles';
import '../../Dirmacom/DirmacomShop/webroot/js/checkout_overview';

$(function() {
    let facets = new Facets;

    if ($('.banner-slider').length) {
        let slider = tns({
            controls: true,
            controlsText: ['', ''],
            autoplayButtonOutput: false,
            //lazyload: true,
            nav: false,
            container: '.banner-slider',
            items: 1,
            slideBy: 'page',
            autoplayHoverPause: true,
            autoplay: false,
            autoHeight: true,
            mouseDrag: true,
            responsive: { }
        });
    }

    // OPTION LIST
    $('.option-dropdown').each(function() {
        var $this = $(this);
        var close = function(e) {
            $this.removeClass('open');
            $('body').off('click', close);
        };

        $this.find('> li').on('click', function(e) {
            e.stopPropagation();

            if (!$this.hasClass('open')) {
                $this.addClass('open');

                $('body').on('click', close);
            } else {
                close(e);
            }
        });
    });

    // Disabled add to cart
    $('a.cart-disabled').on('click', function() {
        var sizeSelect = $('ul.size-select');

        sizeSelect.addClass('animate');
        setTimeout(function() {
            sizeSelect.removeClass('animate');
        }, 1200);
    });

    // PRODUCT ZOOM
    $(".product-assets").each(function() {
        var assets = $(this);
        var images = assets.find(".images > .image");
        var zoomArea = $(this).find(".image-large");

        images.on('click zoom.activate', function() {
          zoomArea.addClass('loading');
          var image = $(this).find("img");
          var zoomImage = $("<img>")
            .attr('src', image.data('normal'))
            .on('load', function(e) {
                assets.find(".image-large").trigger('zoom.destroy');

                zoomArea.empty().append(zoomImage);
                zoomArea.zoom({
                url: image.data('zoom')
                });

                zoomArea.removeClass('loading');
            });

            images.removeClass('active');
            $(this).addClass('active');
        }).eq(0).trigger('zoom.activate');

    });

    // WIZARD
    $('.wizard .disabled > a').on('click', function(e) {
        e.preventDefault();
    });


    // MOBILE
    $('.mobile-menu-burger').on('click', function(e) {
        e.preventDefault();

        $('body').toggleClass('mobile-menu-open');
    });

    $('.mobile-search .search').on('click', function(e) {
        e.preventDefault();
        var p = $(this).parent();
        var close = function() { p.removeClass('open'); }

        if (p.hasClass('open')) {
            close();
        } else {
            p.addClass('open');
            p.find('.query')
                .on('blur', function(e) {
                    setTimeout(close, 100);
                })
                .focus();
        }
    });

    // stock check
    $('.product-stock-wrapper').each(function() {
        var self = $(this);

        $.post('/midelco/midelco/currentstockpershopbyarticlepriceid', { 'sku': self.data('sku') })
            .done(function(data) {
                self.html(data);
            });
    });

});
