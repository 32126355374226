(function($) {

	$.ajaxSetup({ cache: false });

	/*
	 * Shipping cart 'class'
	 */
	PaymentOverview = (function() {

		var $this = this;

		var discountUrl = '/dirmacom_shop/order_codes/midelco_discounts';
		var applyDiscountUrl = '/dirmacom_shop/order_codes/apply_midelco_discount';
		var overviewUrl = '/dirmacom_shop/checkout/overview.json';

		function main() {
			$this.shipping();
			$this.cardElements();
			$this.customerDiscounts();
		}

		this.shipping = function() {
			$('input.shippingCalc').on('change', function() {
				$element = $(this);
				var rule = $element.attr('value');
				var params = {
					shipping_rule: rule
				};

				$this.updateOverview(params);
			});
		};

		this.customerDiscounts = function() {
			var wrapper = $('#customer-discounts');

			if (wrapper.length < 1) {
				return false;
			}

			wrapper.addClass('loading');

			$.ajax({
				url: discountUrl,
				context: document.body
			}).done(function(response) {
				wrapper
					.removeClass('loading')
					.html(response);

				$('.apply-discount', wrapper).on('click', function(event) {
					event.preventDefault();

					$.getJSON(applyDiscountUrl).then(function(response) {
						if (response.status) {
							$this.customerDiscounts();
							$this.updateOverview();
						}
					});
				});
			});
		};

		// this.customerDiscountForm = function() {
		// 	var self = this;
		// 	var formsrc = $('#customer-discount-template').html();
		// 	var formwrapper = $('#customer-discount-form');
		// 	formwrapper.addClass('loading');

		// 	if (!formwrapper.length) {
		// 		return false;
		// 	}

		// 	$.getJSON(discountUrl).then(function(response) {
		// 		formwrapper.removeClass('loading');

		// 		response.hasDiscount = response.totalDiscount > 0;

		// 		var template = Handlebars.compile(formsrc);
		// 		var html = formwrapper.html(template(response));

		// 		var checkbox = html.find('input[type=checkbox]');
		// 		checkbox.on('change', function() {
		// 			self.customerDiscountChecked($(this).is(':checked'));
		// 		});
		// 	});
		// };

		// this.customerDiscountChecked = function(checked) {
		// 	var self = this;
		// 	$.ajax({
		// 		type: 'POST',
		// 		url: discountUrl,
		// 		dataType: 'json',
		// 		data: {
		// 			use_discount: checked ? 1 : 0
		// 		}
		// 	}).then(function(response) {
		// 		self.updateOverview();
		// 	});
		// };

		this.cardElements = function() {
			$('.discount-form-wrapper').each(function() {
				var cardElement = new CardElement($this, this);
			});
		};

		this.updateOverview = function(params) {
			var request = $.ajax({
				dataType: 'json',
				url: overviewUrl,
				data: params
			});

			request.done(function(response) {
				if (response.success) {
					var newOverview = $(response.payload);
					var newMethods = $(response.methods);

					$('#table-wrapper').replaceWith(newOverview);
					$('#methods').replaceWith(newMethods);
					$('#OrderPaymentForm input[type=submit]').attr('value', response.buttonLabel);
				}
			});
		};

		return main;

	})();

	/**
	 * Card element
	 */
	CardElement = (function() {

		var $this = this;

		function init(wrapper, element) {

			$this.wrapper = wrapper;
			$this.element = $(element);
			$this.code = $('input.code', element);
			$this.pin = $('input.pin', element).hide();

			// message wrapper
			$this.messageWrapper = $('<div></div>')
				.hide()
				.appendTo($this.element);

			// add validation event
			$('[type=button]', element).on('click', function(event) {
				event.preventDefault();
				$this.validate();
			}.bind(this));

			$('input.pin, input.code').on('keypress', function(e) {
				if (e.which == 13) {
					$this.validate();
					return false;
				}
			});
		}

		// show message
		this.setMessage = function(message) {

			if (!message.string) {
				return false;
			}

			$this.messageWrapper
				.removeClass()
				.addClass('card-message '+ message.type)
				.empty()
				.text(message.string)
				.show(200);
		};

		this.hideMessage  = function() {
			$this.messageWrapper.hide();
		};

		this.reset = function() {
			$this.pin.val('').hide();
			$this.code.val('');
			$this.hideMessage();
		};

		this.validate = function() {

			var codeValue = $this.code.val(),
				pinValue = $this.pin.val();

			if (codeValue.lengt < 4) {
				return false;
			}

			// hide pin again if length is 0
			if (pinValue.length === 0) {
				$this.pin.hide();
			}

			// build params
			var params = {
				code: codeValue,
				pin: pinValue
			};

			var request = $.ajax({
				dataType: 'json',
				url: '/dirmacom_shop/order_codes/check.json',
				data: params
			});

			request.done(function(response) {
				if (response.message) {
					$this.setMessage(response.message);
				} else {
					$this.hideMessage();
				}

				if (response.codeValid) {
					if (response.pinRequired) {
						$this.pin.show();
						setTimeout(function() {
							$this.pin.focus();
						}, 100);
					} else {
						$this.wrapper.updateOverview();
						$this.reset();
					}
				}
			});
		};

		return init;

	})();

	$(document).ready(function() {
		overviewClass = new PaymentOverview();
	});

})(jQuery);
